.day {
  position: relative;
  height: 0;
  padding-top: calc(100% - 4px);

  border: 2px solid transparent;
  border-radius: 50%;

  overflow: hidden;
  cursor: pointer;
}

.day > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.day.past {
  color: var(--chakra-colors-gray-400);
  cursor: default;
}

.day.today {
  border-color: var(--chakra-colors-blue-200);
}

.day.unavailable {
  padding-top: 100%;
  border: 0;
  color: var(--chakra-colors-gray-400);
  background: var(--striped);
  cursor: default;
}

.day.selecting:not(.hovered) {
  cursor: default;
}

.day.hovered {
  background-color: var(--chakra-colors-gray-200);
}

.day.selected {
  color: var(--chakra-colors-white);
  background-color: var(--chakra-colors-blue-400);
  border-color: var(--chakra-colors-blue-400);
}
