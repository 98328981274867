.legend {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;

  border: 2px solid transparent;
  border-radius: 50%;
}

.legend.free {
  background-color: var(--chakra-colors-white);
  border-color: var(--chakra-colors-gray-400);
}

.legend.booked {
  background: var(--striped);
  border: 0;
}

.legend.selected {
  background-color: var(--chakra-colors-blue-400);
  border-color: var(--chakra-colors-blue-400);
}
